import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import AppLayout from "$components/AppLayout"
import LiquidationMap from '$components/LiquidationMap'
import MainPage from "$components/MainPage"
import classnames from "classnames"
import useInitApp from "$hooks/useInitApp"
import Loader from "$components/Loader"

import 'swiper/css'
import './styles/default.scss'

const App = () => {
  const isInitialized = useInitApp()

  return (
    <>
      <Loader
        isGlobal
        hide={isInitialized}
      />
      <div
        id='app-wrapper'
        className={classnames('app', isInitialized && 'app_inited')}
      >
        <Routes>
          <Route element={<AppLayout isInitialized={isInitialized} />}>
            <Route
              index
              element={<MainPage isInitialized={isInitialized}/>}
            />
            <Route
              path='liquidation-map'
              element={<LiquidationMap/>}
            />
            <Route path=':rest/*' element={<Navigate to='/'/>}/>
          </Route>
        </Routes>
      </div>
    </>
  )
}

export default App
