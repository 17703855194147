import React, {useEffect} from "react"
import { CloseIcon } from "$components/Popup/CloseIcon"
import { Modal } from 'antd'
import Countdown from "./Countdown"
import { links } from "$constants/links"
import './OfferPopup.scss'

const OfferPopup = () => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [isCompleted, setIsCompleted] = React.useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (isCompleted && isOpen) {
      const newWindow = window.open(links.analytics, '_blank')

      if (newWindow !== null) {
        handleClose()
      }
    }
  }, [isCompleted, isOpen])

  return (
    <Modal
      open={isOpen}
      width='700px'
      centered
      onCancel={handleClose}
      footer=''
      className='offer-popup'
      closeIcon={<CloseIcon/>}
      styles={{content: {background: 'rgba(0,0,0)'}}}
    >
      <span className='offer-popup__bg offer-popup__bg_3'/>
      <span className='offer-popup__bg offer-popup__bg_1'/>
      <span className='offer-popup__bg offer-popup__bg_2'/>
      <span className='offer-popup__bg offer-popup__bg_4'/>
      <span className="offer-popup__shadow"/>
      <div className='offer-popup__content'>
        <div className='offer-popup__content-info'>
          <p className="offer-popup__title">
          <span className='offer-popup__title-line offer-popup__title-line_with-icon'>
            {'Эксклюзивная'}
          </span>
            <span className="offer-popup__title-line">
            {'аналитика от профессора'}
          </span>
          </p>
          <p className="offer-popup__list-title">
            {'Вы получаете:'}
          </p>
          <ul className='offer-popup__list'>
            <li>
              {'Знание правил успешного криптоинвестирования и умение избегать потенциальных рисков.'}
            </li>
            <li>
              {'Формирование инвестиционного плана и стратегии,основанной на долгосрочном или краткосрочном инвестировании.'}
            </li>
            <li>
              {'Возможность майнинга и заработка на добыче новых виртуальных монет.'}
            </li>
          </ul>
        </div>
        <div className="offer-popup__footer">
          <p className='offer-popup__footer-title'>
            {'Cкидка 50% на первый месяц'}
          </p>
          <div className='offer-popup__footer-counter'>
            <div className="offer-popup__footer-counter-actions">
              <Countdown onComplete={() => setIsCompleted(true)} />
              <a
                href={links.analytics}
                onClick={handleClose}
                rel='noopener noreferrer'
                target='_blank'
                className="offer-popup__footer-btn"
              >
                {'Забрать скидку'}
              </a>
            </div>
            <p className='offer-popup__footer-counter-notice'>
              {'вас переведет на телеграм в приложение tribute'}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default OfferPopup
