import React from 'react'
import { cryptoExchange } from "$constants/cryptoExchange"

import './TopSellers.scss'

const TopSellers = () => {

  return (
    <div className='top-sellers'>
      <div className='top-sellers__list'>
        {cryptoExchange.map((item, index) => {
          return (
            <a
              key={index}
              href={item.link}
              target='_blank'
              rel='noreferrer'
              className='top-sellers__list-item'
            >

              <div className='top-sellers__list-item-left'>
                <img
                    src={item.img}
                    alt='okex'
                    width='100%'
                    height='32px'
                />
                {
                  <p className='top-sellers__list-item-title'>
                    {
                      item.price &&
                      (
                        <span>
                          {item.price}
                        </span>
                      )
                    }
                    {item.text}
                  </p>
                }
                <a className='top-sellers__list-item-link'>
                  {'Забрать'}
                </a>
              </div>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default TopSellers
