export const cryptoExchange = [
  {
    "price": "",
    "text": "Бонус до 30000$ к депозиту",
    "link": "https://partner.bybit.com/b/Klinkov",
    "img": "/icons/bybit-seeklogo.svg"
  },
  {
    "price": "",
    "text": "Эксклюзивная аналитика",
    "link": "https://t.me/tribute/app?startapp=sinW",
    "img": "/icons/klinkov academy.svg"
  },
  {
    "price": "",
    "text": "Бонус до 5000$ к депозиту",
    "link": "https://bingx.com/partner/professorklinkov",
    "img": "/icons/bingx-seeklogo.svg"
  }
]
