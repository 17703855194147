import { useEffect, useState } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import ContentStore from '$stores/ContentStore';

let _isInitialized = false;

export default function useInitApp() {
  const [isInitialized, setIsInitialized] = useState(_isInitialized);

  const appInitializer = () => {
    setIsInitialized(true);
    _isInitialized = true;
  };

  useEffect(() => {
    const oswaldFont200 = new FontFaceObserver("Oswald", { weight: 200 });
    const oswaldFont300 = new FontFaceObserver("Oswald", { weight: 300 });
    const oswaldFont400 = new FontFaceObserver("Oswald", { weight: 400 });
    const oswaldFont500 = new FontFaceObserver("Oswald", { weight: 500 });
    const oswaldFont600 = new FontFaceObserver("Oswald", { weight: 600 });

    const fontLoadPromise = Promise.all([
      oswaldFont200.load(),
      oswaldFont300.load(),
      oswaldFont400.load(),
      oswaldFont500.load(),
      oswaldFont600.load(),
    ]).then(() => {
      console.log("Все начертания Oswald загружены!");
    }).catch((err) => {
      console.error("Ошибка загрузки шрифтов:", err);
    });

    const fetchConfigPromise = fetch('/config.json')
      .then(response => response.json())
      .then(response => {
        console.log("Конфигурация загружена:", response);
        ContentStore.mergeOntoState(response);
      })
      .catch(error => {
        console.error("Ошибка загрузки config.json файла:", error);
      });

    Promise.all([fontLoadPromise, fetchConfigPromise]).then(() => {
      if (!_isInitialized) {
        appInitializer();
      }
    });
  }, []);

  return isInitialized;
}
