import { useEffect, useState } from 'react';

const defaultHeight = 350;

const getBubbleChart = async () => {
  return new Promise(resolve => {
    const intervalId = setInterval(() => {
      const bubbleChart = document.querySelector('.bubble-chart');

      if (bubbleChart) {
        resolve(bubbleChart);
        clearInterval(intervalId);
      }
    }, 1000);
  });
};

const useInitBubbles = (innerWidth, innerHeight, bubblesWrapRef) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Проверяем, загружен ли скрипт
    if (document.getElementById('bubbles')) {
      setLoaded(true)
      return
    }

    // Если скрипт не загружен, то создаем его
    const scriptTag = document.createElement('script');
    scriptTag.src = 'assets/bubbleCode.js';
    scriptTag.id = 'bubbles';
    scriptTag.nonce = (Math.random() * 100000).toFixed(0).toString();

    scriptTag.onload = () => {
      setLoaded(true);

      const interval = setInterval(() => {
        // Если элемент найден, очищаем интервал
        if (document.querySelector('.bubble-chart')) {
          clearInterval(interval);
        } else {
          window.dispatchEvent(new Event('load'));
        }
      }, 1000);
    };

    document.head.appendChild(scriptTag);

    // Добавляем стили, если они еще не добавлены
    const cssLinks = [
      { rel: 'stylesheet', href: 'assets/bubbleStyles.css', id: 'bubble-styles' },
      { rel: 'stylesheet', href: 'assets/reset.css', id: 'reset-styles' },
    ];

    cssLinks.forEach(({ rel, href, id }) => {
      if (!document.getElementById(id)) {
        const linkTag = document.createElement('link');
        linkTag.rel = rel;
        linkTag.href = href;
        linkTag.id = id;
        document.head.appendChild(linkTag);
      }
    });
  }, []); // Пустой массив зависимостей, чтобы эффект выполнялся только один раз при монтировании

  useEffect(() => {
    if (!loaded) return;

    (async () => {
      const height = bubblesWrapRef?.current?.getBoundingClientRect?.()?.height || defaultHeight;
      const block1 = document.getElementById('bubbles-header');

      const currentBubbleHeight = height - block1?.getBoundingClientRect?.()?.height;
      const bubbleChart = await getBubbleChart()

      if (bubbleChart) {
        bubbleChart.style.height = `${currentBubbleHeight}px`;
      }
    })();
  }, [loaded, innerWidth, innerHeight, bubblesWrapRef]);

  return [loaded];
};

export default useInitBubbles;
