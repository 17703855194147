import {header} from "$constants/links"
import HeaderLink from "./HeaderLink"
import React from "react"
import classnames from "classnames"

const HeaderSubItemMobile = ({ item, onClick }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <li
      className='header-links__item header-links__item_sub-mobile'
      onClick={() => setIsOpen(prev => !prev)}
    >
      <div className={classnames('header-links__item-title' as any, isOpen && 'header-links__item-title_open' as any)}>
        {item.title}
      </div>

      {isOpen && (
        <ul className="header-links__item-sub-mobile">
          {item.sub.map(i => (
            <HeaderLink
              key={i.title}
              onClick={() => {
                onClick()
                i.onClick && i.onClick()
              }}
              title={i.title}
              link={i.link}
              external={i.external}
            />
          ))}
        </ul>
      )}
    </li>
  )
}

const HeaderLinksMobile = ({ onClick }: { onClick: () => void }) => {
  return (
    <ul className='header-links header-links_mobile'>
      {header.map(item => {
        if (item.sub) {
          return (
            <HeaderSubItemMobile
              item={item}
              onClick={onClick}
            />
          )
        }

        return (
          <li
            key={item.title}
            className='header-links__item'
          >
            <HeaderLink
              onClick={() => {
                onClick()
                item.onClick && item.onClick()
              }}
              title={item.title}
              link={item.link}
              external={item.external}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default HeaderLinksMobile
