import React from 'react'

export const CloseIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='24' height='24' rx='12' fill='#DBB466' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.4141 12L15.7071 9.70701C16.0981 9.31601 16.0981 8.68401 15.7071 8.29301C15.3161 7.90201 14.6841
            7.90201 14.2931 8.29301L12.0001 10.586L9.70713 8.29301C9.31613 7.90201 8.68413 7.90201 8.29313
            8.29301C7.90213 8.68401 7.90213 9.31601 8.29313 9.70701L10.5861 12L8.29313 14.293C7.90213 14.684
            7.90213 15.316 8.29313 15.707C8.48813 15.902 8.74413 16 9.00013 16C9.25613 16 9.51213 15.902 9.70713
            15.707L12.0001 13.414L14.2931 15.707C14.4881 15.902 14.7441 16 15.0001 16C15.2561 16 15.5121 15.902
            15.7071 15.707C16.0981 15.316 16.0981 14.684 15.7071 14.293L13.4141 12Z'
      fill='#162136'
    />
  </svg>
)
