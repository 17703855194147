import React from "react"
import { header } from "$constants/links"
import HeaderLink from "./HeaderLink"

import './HeaderLinks.scss'

const HeaderLinks = () => {
  return (
    <ul className='header-links'>
      {header.map(item => {
        if (item.sub) {
          return (
            <li
              key={item.title}
              className='header-links__item header-links__item_sub'
            >
              <div className='header-links__item-title'>
                {item.title}
              </div>

              <ul className="header-links__item-sub">
                {item.sub.map(i => (
                  <HeaderLink
                    {...(i.onClick ? { onClick: i.onClick } : {})}
                    key={i.title}
                    title={i.title}
                    link={i.link}
                    external={i.external}
                  />
                ))}
              </ul>
            </li>
          )
        }

        return (
          <li
            key={item.title}
            className='header-links__item'
          >
            <HeaderLink
              {...(item.onClick ? { onClick: item.onClick } : {})}
              title={item.title}
              link={item.link}
              external={item.external}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default HeaderLinks
