import React from 'react'
import classnames from 'classnames'
import Title from '$components/Title'

import './GridItemWrap.scss'

interface IProps {
  title?: string
  shadow?: boolean
  children: any
  isAnimate: boolean
  isBubbles?: boolean
  withPadding?: boolean
  withStyles?: boolean
  name: string
  id?: string
}

const GridItemWrap = ({
  title,
  shadow = true,
  isAnimate = false,
  id,
  withPadding = true,
  withStyles = true,
  children,
  name = ''
}: IProps) => {

  return (
    <div
      id={id}
      className={
      classnames(
        'grid-item',
        `grid-item_${name}`,
        isAnimate && 'grid-item_animate',
        withStyles && 'grid-item_styles',
        )}
    >
      {shadow && withStyles && (
        <div className='grid-item__decor'>
          <div className='grid-item__decor_top' />
          <div className='grid-item__decor_right' />
          <div className='grid-item__decor_left' />
        </div>
      )}
      <div className={classnames(
        'grid-item__content',
        (!withPadding || !withStyles) && 'grid-item__content_no-padding',
        )}
      >
        {title && (
          <Title>
            {title}
          </Title>
        )}
        {children}
      </div>
    </div>
  )
}

export default GridItemWrap
