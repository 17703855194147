import React, {useEffect, useState} from 'react'
import classnames from 'classnames'

import './Loader.scss'

interface IProps {
  isGlobal?: boolean
}

const Loader = ({ isGlobal, hide }: IProps) => {
  const [isHide, setIsHide] = useState(false)

  useEffect(() => {
    if (hide) {

      setTimeout(() => setIsHide(true), 1500)
    }
  }, [hide])

  if (isHide) return null

  return (
    <div className={classnames('loader', isGlobal && 'loader_global', hide && 'loader_hide')}>
      <div className='loader__img'>
        <div className='loader__img-animation'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <svg
          className='loader__img-svg'
          xmlns='http://www.w3.org/2000/svg'
          width='120'
          height='48'
          viewBox='0 0 120 48'
          fill='none'
        >
          <path
            /* eslint-disable-next-line */
            d='M55.104 14.0021H56.7547V17.4996H59.909L62.2951 14.0021H64.2073L61.3308 18.1861L64.4361 23.04H62.4749L59.958 19.0032H56.7547V23.04H55.104V14.0021Z'
            fill='white'
          />
          <path
            d='M65.6857 14.0021H67.3527V21.5365H73.8574V23.04H65.6857V14.0021Z'
            fill='white'
          />
          <path
            d='M75.1821 14.0021H76.8492V23.04H75.1821V14.0021Z'
            fill='white'
          />
          <path
            /* eslint-disable-next-line */
            d='M78.1977 14.0021H79.8647L85.8627 20.7193V14.0021H87.5134V23.04H85.8301L79.832 16.2902V23.04H78.1977V14.0021Z'
            fill='white'
          />
          <path
            /* eslint-disable-next-line */
            d='M88.7978 14.0021H90.4484V17.4996H93.6027L95.9889 14.0021H97.901L95.0246 18.1861L98.1298 23.04H96.1686L93.6517 19.0032H90.4484V23.04H88.7978V14.0021Z'
            fill='white'
          />
          <path
            /* eslint-disable-next-line */
            d='M100.8 16.1268C100.169 16.7151 99.8525 17.516 99.8525 18.5293C99.8525 19.5426 100.169 20.3488 100.8 20.9481C101.443 21.5365 102.255 21.8306 103.236 21.8306C104.216 21.8306 105.028 21.5365 105.671 20.9481C106.314 20.3488 106.635 19.5426 106.635 18.5293C106.635 17.516 106.314 16.7151 105.671 16.1268C105.028 15.5275 104.216 15.2279 103.236 15.2279C102.255 15.2279 101.443 15.5275 100.8 16.1268ZM106.831 15.1135C107.801 15.9851 108.286 17.1237 108.286 18.5293C108.286 19.9348 107.801 21.0788 106.831 21.9614C105.861 22.833 104.663 23.2689 103.236 23.2689C101.808 23.2689 100.61 22.833 99.6401 21.9614C98.6704 21.0788 98.1855 19.9348 98.1855 18.5293C98.1855 17.1237 98.6704 15.9851 99.6401 15.1135C100.61 14.2309 101.808 13.7897 103.236 13.7897C104.663 13.7897 105.861 14.2309 106.831 15.1135Z'
            fill='white'
          />
          <path
            /* eslint-disable-next-line */
            d='M108.538 14.0021H110.369L113.556 21.5365H114.209L117.396 14.0021H119.211L115.223 23.04H112.51L108.538 14.0021Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            /* eslint-disable-next-line */
            d='M73.8151 26.2642C74.783 27.1343 75.2669 28.2707 75.2669 29.6737C75.2669 31.0766 74.783 32.2185 73.8151 33.0994C72.8472 33.9694 71.6509 34.4044 70.2262 34.4044C68.8015 34.4044 67.6052 33.9694 66.6373 33.0994C65.6694 32.2185 65.1855 31.0766 65.1855 29.6737C65.1855 28.2707 65.6694 27.1343 66.6373 26.2642C67.6052 25.3833 68.8015 24.9429 70.2262 24.9429C71.6509 24.9429 72.8472 25.3833 73.8151 26.2642ZM67.7956 27.2756C67.1648 27.8629 66.8494 28.6622 66.8494 29.6737C66.8494 30.6851 67.1648 31.4898 67.7956 32.088C68.4372 32.6753 69.2474 32.9689 70.2262 32.9689C71.205 32.9689 72.0152 32.6753 72.6568 32.088C73.2985 31.4898 73.6193 30.6851 73.6193 29.6737C73.6193 28.6622 73.2985 27.8629 72.6568 27.2756C72.0152 26.6775 71.205 26.3784 70.2262 26.3784C69.2474 26.3784 68.4372 26.6775 67.7956 27.2756Z'
            fill='#DBB566'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            /* eslint-disable-next-line */
            d='M86.7052 29.6737C86.7052 28.2707 86.2212 27.1343 85.2533 26.2642C84.2854 25.3833 83.0891 24.9429 81.6645 24.9429C80.2398 24.9429 79.0435 25.3833 78.0756 26.2642C77.1077 27.1343 76.6237 28.2707 76.6237 29.6737C76.6237 31.0766 77.1077 32.2185 78.0756 33.0994C79.0435 33.9694 80.2398 34.4044 81.6645 34.4044C83.0891 34.4044 84.2854 33.9694 85.2533 33.0994C86.2212 32.2185 86.7052 31.0766 86.7052 29.6737ZM78.2877 29.6737C78.2877 28.6622 78.603 27.8629 79.2338 27.2756C79.8755 26.6775 80.6857 26.3784 81.6645 26.3784C82.6432 26.3784 83.4535 26.6775 84.0951 27.2756C84.7368 27.8629 85.0576 28.6622 85.0576 29.6737C85.0576 30.6851 84.7368 31.4898 84.0951 32.088C83.4535 32.6753 82.6432 32.9689 81.6645 32.9689C80.6857 32.9689 79.8755 32.6753 79.2338 32.088C78.603 31.4898 78.2877 30.6851 78.2877 29.6737Z'
            fill='#DBB566'
          />
          <path
            /* eslint-disable-next-line */
            d='M106.35 26.1827L105.55 27.4061C104.528 26.7427 103.326 26.411 101.945 26.411C100.27 26.411 99.4331 26.8134 99.4331 27.6182C99.4331 27.9336 99.5527 28.162 99.792 28.3034C100.031 28.4339 100.385 28.5263 100.852 28.5807L103.658 28.9069C105.659 29.1571 106.66 30.0325 106.66 31.5333C106.66 32.4904 106.247 33.2082 105.42 33.6867C104.604 34.1652 103.49 34.4044 102.076 34.4044C100.303 34.4044 98.7806 33.9477 97.5081 33.0341L98.2912 31.7454C99.4875 32.5502 100.831 32.9526 102.32 32.9526C103.158 32.9526 103.821 32.8384 104.311 32.61C104.811 32.3708 105.061 32.0227 105.061 31.566C105.061 30.9243 104.593 30.5546 103.658 30.4567L100.689 30.1304C99.743 30.0217 99.0253 29.7715 98.5359 29.38C98.0465 28.9885 97.8018 28.4067 97.8018 27.6345C97.8018 26.7536 98.1879 26.0848 98.96 25.628C99.743 25.1713 100.738 24.9429 101.945 24.9429C103.707 24.9429 105.175 25.3561 106.35 26.1827Z'
            fill='#DBB566'
          />
          <path
            d='M88.2577 25.1549H89.9217V32.6753H96.4143V34.1761H88.2577V25.1549Z'
            fill='#DBB566'
          />
          <path
            d='M64.3485 25.1549H55.0175V26.6557H58.851V34.1761H60.515V26.6557H64.3485V25.1549Z'
            fill='#DBB566'
          />
          {/*<path*/}
          {/*  fillRule='evenodd'*/}
          {/*  clipRule='evenodd'*/}
          {/*  d='M24 44.352C35.2401 44.352 44.352 35.2401 44.352 24C44.352 12.7599 35.2401 3.648 24 3.648C12.7599 3.648 3.648 12.7599 3.648 24C3.648 35.2401 12.7599 44.352 24 44.352ZM24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'*/}
          {/*  fill='white'*/}
          {/*/>*/}
          <path
            className='loader__img-svg-symbol'
            /* eslint-disable-next-line */
            d='M15.456 15.016C15.456 14.4637 15.9038 14.016 16.456 14.016H18.1918C18.7441 14.016 19.1918 14.4637 19.1918 15.016V20.7741C19.1918 21.3264 19.6395 21.7741 20.1918 21.7741H25.6053C25.9385 21.7741 26.2499 21.6081 26.4356 21.3315L31.0495 14.4586C31.2352 14.182 31.5466 14.016 31.8798 14.016H33.7986C34.5987 14.016 35.0748 14.909 34.6289 15.5733L29.9128 22.5994C29.6914 22.9293 29.6863 23.3589 29.8997 23.694L35.5969 32.6388C36.0209 33.3045 35.5428 34.176 34.7535 34.176H32.6778C32.3406 34.176 32.0262 34.0061 31.8414 33.7241L26.5455 25.6438C26.3607 25.3618 26.0463 25.1919 25.7092 25.1919H20.1918C19.6395 25.1919 19.1918 25.6396 19.1918 26.1919V32.12C19.1918 32.6723 18.7441 33.12 18.1918 33.12H16.456C15.9038 33.12 15.456 32.6723 15.456 32.12V26.1919C15.456 25.6396 15.0083 25.1919 14.456 25.1919H10.1362C9.33513 25.1919 8.85922 24.2972 9.30697 23.6329L10.2627 22.2151C10.4486 21.9394 10.7594 21.7741 11.0919 21.7741H14.456C15.0083 21.7741 15.456 21.3264 15.456 20.7741V15.016Z'
            fill='white'
          />
        </svg>
      </div>
    </div>
  )
}

export default Loader
