import React from 'react'
import { Timer } from "voothanh-react-countdown-flip"
import './Countdown.scss'

function formatDateWithAddedMinutes(minutesToAdd) {
  const now = new Date();

  now.setMinutes(now.getMinutes() + minutesToAdd);

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const Countdown = ({ onComplete }) => {

  return (
    <Timer
      target={formatDateWithAddedMinutes(2)}
      display={{
        years: false,
        days: false,
        hours: false,
        minutes: true,
        seconds: true
      }}
      onComplete={onComplete}
    />
  )
}

export default Countdown
