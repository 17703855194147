import React, {useEffect, useState} from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import {client} from "$api/index"
import classnames from "classnames"

import './KnowledgeBase.scss'

function transformDate(inputDate) {
  let result = ''

  try {
    const date = new Date(inputDate);

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    result = `${day}.${month}.${year}`
  } catch (e) {
    console.log(e)
  }

  return result
}


const KnowledgeBaseItem = ({ title, link, image, date = '', duration }) => {
  const [imgLoaded, setImgLoaded] = useState(false)

  const resultDate = transformDate(date)

  return (
    <div className='knowledge-base__item'>
      <img
        className={classnames('knowledge-base__item-image', imgLoaded && 'knowledge-base__item-image_loaded')}
        src={image}
        alt={title}
        onLoad={() => setImgLoaded(true)}
      />
      <div className="knowledge-base__item-info">
        <p className='knowledge-base__item-title'>
          {title}
        </p>
        <div className="knowledge-base__item-meta">
          <span>
            {duration}
          </span>
          {resultDate ? (
            <span>
              {resultDate}
            </span>
          ) : null}
        </div>
      </div>
      <a
        href={link}
        rel='noopener noreferrer'
        target='_blank'
        className='knowledge-base__item-link'
      />
    </div>
  )
}

const KnowledgeBase = () => {
  const [data, setData] = useState([])

  useEffect(() => {

    (async () => {
      try {
        const data = await client.get('https://klinkovacademy.com/wp-json/wp/v2/posts?per_page=100')

        if (data.data) {
          const filteredData = data.data
            .filter(i => i.featured_media)
            .map(i => ({
              id: i.id,
              title: i.title.rendered,
              link: i.link,
              date: i.date,
              image: i.yoast_head_json?.og_image?.[0]?.url || '',
              duration: i.yoast_head_json?.twitter_misc?.['Примерное время для чтения'] || '',
            }))

          if (filteredData?.length) {
            setData(filteredData)
          } else {
            throw 'data is broken'
          }
        } else {
          throw 'data is undefined'
        }
      } catch (e) {
        console.log('e', e)
      }
    })()

  }, [])

  return (
    <div className="knowledge-base">
      <div className="knowledge-base__head">
        <div className='knowledge-base__title'>
          <h3 className="knowledge-base__title-text">
            {'База знаний'}
          </h3>
          <span className='knowledge-base__title-shadow'>
          {'Knowledge base'}
        </span>
        </div>
      </div>
      <Swiper
        className='knowledge-base__swiper'
        slidesPerView={1}
        navigation={true}
        spaceBetween={30}
        loop
        breakpoints={{
          650: {
            spaceBetween: 30,
            slidesPerView: 2,
          },
          1124: {
            spaceBetween: 40,
            slidesPerView: 3,
          }
        }}
        speed={1000}
        modules={[Navigation]}
      >
        {data.length ? data.map(item => {
          return (
            <SwiperSlide key={item.id}>
              <KnowledgeBaseItem
                title={item.title}
                link={item.link}
                image={item.image}
                date={item.date}
                duration={item.duration}
              />
            </SwiperSlide>
          )
        }) : (
          <>
            <SwiperSlide key="placeholder-1">
              <div className="knowledge-base__item"/>
            </SwiperSlide>
            <SwiperSlide key="placeholder-2">
              <div className="knowledge-base__item"/>
            </SwiperSlide>
            <SwiperSlide key="placeholder-3">
              <div className="knowledge-base__item"/>
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </div>
  )
}

export default KnowledgeBase
