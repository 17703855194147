import React, {Suspense} from "react"
import Bubbles from '$components/Bubbles'
import AltcoinIndex from "$components/AltcoinIndex"
import FearIndicator from '$components/FearIndicator'
import GridItemWrap from '$components/GridItemWrap'
import Telegram from '$components/Telegram'
import TopSellers from '$components/TopSellers'
import Youtube from '$components/Youtube'
import Loader from "$components/Loader"
import LiquidationMap from "$components/LiquidationMap"
import KnowledgeBase from "$components/KnowledgeBase"

const MainPage = ({ isInitialized }) => {
  return (
    <>
    <div className='app__top'>
    <GridItemWrap
      title='Altcoin index & dominance'
      name='altcoin-index'
      isAnimate={isInitialized}
    >
      <AltcoinIndex />
    </GridItemWrap>

    <GridItemWrap
      name='fear-and-greed'
      title='Fear & Greed Index'
      isAnimate={isInitialized}
    >
      <Suspense fallback={<Loader />}>
        <FearIndicator />
      </Suspense>
    </GridItemWrap>

    <GridItemWrap
        name='top-sellers'
        title='ТОП Криптобирж'
        isAnimate={isInitialized}
    >
      <TopSellers />
    </GridItemWrap>

    <GridItemWrap
      id='bubbles-section'
      name='bubbles'
      isAnimate={isInitialized}
      withPadding={false}
    >
      <Suspense fallback={<Loader />}>
        <Bubbles />
      </Suspense>
    </GridItemWrap>

    <GridItemWrap
        name='telegram'
        title='Telegram'
        withPadding={false}
        isAnimate={isInitialized}
    >
      <Telegram />
    </GridItemWrap>

    <GridItemWrap
        title='Канал YouTube'
        name='youtube'
        isAnimate={isInitialized}
    >
      <Suspense fallback={<Loader />}>
        <Youtube />
      </Suspense>
    </GridItemWrap>

      <GridItemWrap
        withPadding={false}
        name='liquidation-map'
        isAnimate={isInitialized}
      >
        <LiquidationMap />
      </GridItemWrap>

      <GridItemWrap
        id='knowledge-base'
        name='knowledge-base'
        withStyles={false}
        withPadding={false}
        isAnimate={isInitialized}
      >
        <KnowledgeBase />
      </GridItemWrap>

    <GridItemWrap
      isAnimate={isInitialized}
      shadow={false}
      name='footer'
    >
      <footer className='footer'>
        <p className='footer__copyright'>
          {'© 2024'}
        </p>
        <p className='footer__developer'>
          {'made with 🤍 by easy agency'}
        </p>
      </footer>
    </GridItemWrap>
  </div>
    </>
  )
}

export default MainPage
