import { Link } from "react-router-dom"
import React from "react"

const HeaderLink = ({ title, link, external, onClick }) => {
  if (external) {
    return (
      <a
        onClick={onClick}
        className='header-links__item-link'
        href={link}
        target='_blank'
        rel='noopener noreferrer'
      >
        {title}
      </a>
    )
  } else {
    return (
      <Link
        onClick={onClick}
        className='header-links__item-link'
        to={link}
      >
        {title}
      </Link>
    )
  }
}

export default HeaderLink
