import React, {useState} from 'react'
import {links} from "$constants/links"
import MainBannerImg from "../../images/main-banner.png"
import UserStore from "$stores/UserStore"
import './MainBanner.scss'
import classnames from "classnames";

const MainBanner = () => {
  const [isLoaded, setLoaded] = useState<boolean>(false)

  const handleClose = () => {
    UserStore.mergeOntoState({
      offer: {
        ...UserStore.state.offer,
        isShowFirst: false,
      }
    })
  }

  return (
    <div className='main-banner'>
      <a
        href={links.analytics}
        rel="noopener noreferrer"
        target='_blank'
        onClick={handleClose}
        className="main-banner__link"
      />
      <button
        onClick={handleClose}
        className="main-banner__close"
      />
      <img
        alt='Эксклюзивная аналитика'
        src={MainBannerImg as string}
        className={classnames('main-banner__img', isLoaded && 'main-banner__img_loaded')}
        onLoad={() => setLoaded(true)}
      />
      {isLoaded && (
        <a
          href={links.analytics}
          rel="noopener noreferrer"
          target='_blank'
          onClick={handleClose}
          className="main-banner__btn"
        >
          {'Забрать скидку'}
        </a>
      )}
    </div>
  )
}

export default MainBanner
