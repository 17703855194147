import React, {useEffect, useState} from 'react'
import {client} from "$api/index"
import Loader from "$components/Loader"
import './AltcoinIndex.scss'

const AltcoinIndex = () => {
  const [index, setIndex] = useState(0)
  const [dominance, setDominance] = useState({
    btc: 0,
    eth: 0,
  })

  const [loaded, setLoaded] = useState({
    index: false,
    dominance: false,
  })

  useEffect(() => {
    client.get('/altcoin-index').then(res => {
      setIndex(res.data.index)
      setLoaded(prev => ({ ...prev, index: true }))
    }).catch(() => {})

    client.get('/btc-dominance').then(res => {
      setDominance({
        btc: res.data.data?.dominance?.[0]?.mcProportion?.toFixed(2),
        eth: res.data.data?.dominance?.[1]?.mcProportion?.toFixed(2),
      })
      setLoaded(prev => ({ ...prev, dominance: true }))
    }).catch(() => {})
  }, [])

  if (!loaded.index && !loaded.dominance) {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '220px'
      }}>
        <Loader/>
      </div>
    )
  }

  return (
    <div className='altcoin-index'>
      <div className="altcoin-index__index">
        <p className="altcoin-index__index-title">
          {'Altcoin index'}
        </p>
        <div className="altcoin-index__index-percentage">
          <span>
          {index}
          </span>
          {'/100'}
        </div>
        <div className="altcoin-index__index-progress">
          <span className="altcoin-index__index-progress-line altcoin-index__index-progress-line_first" />
          <span className="altcoin-index__index-progress-line altcoin-index__index-progress-line_second" />
          <span className="altcoin-index__index-progress-line altcoin-index__index-progress-line_third" />
          <div
            style={{
              left: `calc(${index}% - 8px)`
            }}
            className="altcoin-index__index-progress-point" />
        </div>
        <div className="altcoin-index__index-progress-notice">
          <p>
            {'Bitcoin'}
            <br/>
            {'Season'}
          </p>
          <p>
            {'Altcoin'}
            <br/>
            {'Season'}
          </p>
        </div>
      </div>
      <div className="altcoin-index__dominance">
        <p className='altcoin-index__dominance-title'>
          {'Dominance'}
        </p>
        <div className='altcoin-index__dominance-items'>
          <div className="altcoin-index__dominance-item altcoin-index__dominance-item_btc">
            <p className="altcoin-index__dominance-item-title">
              {'Bitcoin'}
            </p>
            <p className="altcoin-index__dominance-item-value">
              {`${dominance.btc}%`}
            </p>
          </div>
          <div className="altcoin-index__dominance-item altcoin-index__dominance-item_eth">
            <p className="altcoin-index__dominance-item-title">
              {'Ethereum'}
            </p>
            <p className="altcoin-index__dominance-item-value">
              {`${dominance.eth}%`}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AltcoinIndex
