import { scrollToElementById } from "$utils/scrollToElementById"

export const links = {
  analytics: 'https://web.tribute.tg/s/mRM',
  lesson: "https://t.me/tribute/app?startapp=sinW",
  youtube: "https://www.youtube.com/@KlinkovProfessor",
  telegram: "https://t.me/ProfessorKlinkov/",
  klinkov_capital: "https://klinkovcapital.com/"
}

export const header = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'База знаний',
    link: '#knowledge-base',
    onClick: () => scrollToElementById('knowledge-base')
  },
  {
    title: 'Рейтинги',
    link: 'https://klinkovacademy.com/rejting-kriptobirzh',
    external: true,
  },
  {
    title: 'Обучение',
    sub: [
      {
        title: 'Курс "Капитал"',
        link: 'https://klinkovacademy.com/capital/',
        external: true,
      }
    ]
  },
  {
    title: 'Клуб',
    link: 'https://klinkovcapital.com/',
    external: true,
  },
] as any[]
