import React, {useEffect, useState} from 'react'
import classnames from 'classnames'
import UserStore from "$stores/UserStore"
import './OfferWidget.scss'
import { links } from "$constants/links"

const OfferWidget = () => {
  const [isTitleShow, setIsTitleShow] = useState<boolean>(true)

  const handleClose = () => {
    UserStore.mergeOntoState({
      offer: {
        ...UserStore.state.offer,
        isShowSecond: false,
      }
    })
  }

  useEffect(() => {
    setInterval(() => {
      setIsTitleShow(prev => !prev)
    }, 5000)
  }, [])

  return (
    <div className='offer-widget'>
      <div className="offer-widget__container">
        <span className='offer-widget__k-decor' />
        <span className='offer-widget__shadow-decor' />
        <span
          className={
            classnames(
              'offer-widget__graph-decor',
              isTitleShow ? 'offer-widget__graph-decor_up' : 'offer-widget__graph-decor_down'
            )
          }
        />
        <span
          className={
            classnames(
              'offer-widget__iphone-decor',
              isTitleShow ? 'offer-widget__iphone-decor_show' : 'offer-widget__iphone-decor_hide'
            )
          }
        />
        <span className={
          classnames(
            'offer-widget__gradient-decor',
            isTitleShow ? 'offer-widget__gradient-decor_up' : 'offer-widget__gradient-decor_down')
        } />
        <button
          className='offer-widget__content-close'
          onClick={handleClose}
        />
        <div className="offer-widget__content">
          <p className={
            classnames(
              'offer-widget__content-title',
              isTitleShow ? 'offer-widget__content-title_up' : 'offer-widget__content-title_down'
            )
          }>
            {'Эксклюзивная аналитика'}
          </p>
          <div
            className={
            classnames(
              'offer-widget__content-discount',
              isTitleShow ? 'offer-widget__content-discount_hide' : 'offer-widget__content-discount_show'
            )
            }
          />
          <a
            href={links.analytics}
            target='_blank'
            rel="noopener noreferrer"
            onClick={() => {
              UserStore.mergeOntoState({
                offer: {
                  ...UserStore.state.offer,
                  isShowSecond: false,
                }
              })
            }}
            className='offer-widget__content-btn'
          >
            {'забрать скидку'}
          </a>
        </div>
      </div>
    </div>
  )
}

export default OfferWidget
