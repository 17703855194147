import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { Drawer } from 'antd'
import { header, links } from "$constants/links"
import { CloseIcon } from "$components/Popup/CloseIcon"
import HeaderLinks from "./components/HeaderLinks"
import HeaderLinksMobile from "./components/HeaderLinks/HeaderLinksMobile"
import Logo from '../../images/logo.svg'

import './Header.scss'

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setDrawerOpen(prev => !prev)
  }

  return (
    <header className='site-header'>
      <Link
        to='/'
        className='logo'
      >
        <img
          src={Logo as string}
          alt='Klinkov tools logo'
        />
      </Link>
      <div className='site-header__content'>
        <HeaderLinks />
        <div className="header-icons">
          <a
            className='header-icons__item header-icons__item_youtube'
            href={links.youtube}
            rel='noreferrer'
            target='_blank'
          />
          <a
            className='header-icons__item header-icons__item_telegram'
            href={links.telegram}
            rel='noreferrer'
            target='_blank'
          />
          <div className='header-icons__item-burger' onClick={toggleDrawer}>
            <div className='header-icons__item-burger-line'/>
            <div className='header-icons__item-burger-line'/>
            <div className='header-icons__item-burger-line'/>
          </div>
        </div>
      </div>
      <Drawer
        title='Меню'
        placement='right'
        closable={false}
        onClose={toggleDrawer}
        open={drawerOpen}
        className='header__drawer'
        style={{
          color: '#fff',
          backgroundColor: '#060604',
          position: 'relative'
        }}
      >
        <button
          className='site-header__drawer-close'
          onClick={() => setDrawerOpen(false)}
        >
          <CloseIcon />
        </button>
        <HeaderLinksMobile onClick={() => setDrawerOpen(false)} />
      </Drawer>
    </header>
  )
}

export default Header
